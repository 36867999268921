import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import Breadcrumb from '../../examples/Breadcrumb';
export const query = graphql`
  query BreadcrumbQuery {
    BreadcrumbItem: componentMetadata(displayName: { eq: "BreadcrumbItem" }) {
      ...ComponentApi_metadata
    }
    Breadcrumb: componentMetadata(displayName: { eq: "Breadcrumb" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2 {...{
      "id": "breadcrumbs"
    }}>{`Breadcrumbs`}</h2>
    <p>{`Indicate the current page’s location within a navigational
hierarchy that automatically adds separators via CSS. Add `}<inlineCode parentName="p">{`active `}</inlineCode>{`
prop to active  `}<inlineCode parentName="p">{`Breadcrumb.Item `}</inlineCode>{`. Do not
set both  `}<inlineCode parentName="p">{`active `}</inlineCode>{` and  `}<inlineCode parentName="p">{`href `}</inlineCode>{` attributes. `}<inlineCode parentName="p">{`active `}</inlineCode>{` overrides  `}<inlineCode parentName="p">{`href `}</inlineCode>{` and  `}<inlineCode parentName="p">{`span `}</inlineCode>{`
element is rendered instead of  `}<inlineCode parentName="p">{`a `}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <ReactPlayground codeText={Breadcrumb} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.Breadcrumb} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.BreadcrumbItem} exportedBy={props.data.Breadcrumb} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      